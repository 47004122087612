<template>
  <div>
    <div class="form-group">
      <label for="password">Code</label>
      <div class="position-relative">
        <input
          id="code"
          v-model="code"
          v-validate="'required'"
          type="text"
          class="form-control"
          name="code"
          autocomplete="off"
          :class="{ 'is-invalid': errors.has('code') }"
        >
        <span
          v-show="errors.has('code')"
          class="form-text text-danger small"
        >{{ errors.first('code') }}</span>
      </div>
    </div>

    <div class="form-group">
      <label for="password">New Password</label>
      <div class="position-relative">
        <input
          id="password"
          v-model="newPassword"
          v-validate="passwordValidation"
          type="password"
          class="form-control"
          name="password"
          autocomplete="new-password"
          :class="{ 'is-invalid': errors.has('password') }"
        >
      </div>
      <span class="form-text text-muted small">
        At least eight characters in length, including at least one letter and one number (without leading and trailing spaces).
      </span>
      <span
        v-show="errors.has('password')"
        class="form-text text-danger small"
      >
        {{ errors.first('password') }}
      </span>
    </div>

    <div class="text-center">
      <button
        class="btn btn-primary w-50"
        :disabled="isSubmitting"
        @click="resetPassword"
      >
        Reset Password
      </button>
    </div>
    <notifications position="bottom right" />
  </div>
</template>

<script>
import http from 'common/http';
import passwordValidator from 'mixins/password_validator';

export default {
  $_veeValidate: {
    validator: "new"
  },
  mixins: [passwordValidator],
  props: ['forgotEmail'],
  data() {
    return {
      code: '',
      newPassword: '',
      isSubmitting: false,
    };
  },
  methods: {
    resetPassword() {
      const user = {
        email: this.forgotEmail,
        resetPasswordToken: this.code,
        password: this.newPassword
      };

      this.$validator.validateAll().then(result => {
        if (!result) {
          this.emitError("Please correct errors in the form before submitting");
          return;
        }

        this.isSubmitting = true;
        http
          .put('/confirm_new_password', {user})
          .then(() => {
            this.emitSuccess('Password successfully updated');
            this.$router.replace('/users/sign_in');
          })
          .catch(error => {
            this.emitError(error.response.data.message);
            this.code = '';
            this.newPassword = '';
            this.goToForgotEmailStep();
          })
          .finally(() => {
            this.isSubmitting = false;
          })
      })
    },
    goToForgotEmailStep() {
      this.$emit('forgot-email-step');
    }
  }

};
</script>
