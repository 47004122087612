import Login from '../components/authentication/login.vue';
import ForgotPassword from '../components/authentication/forgot_password.vue';
import { scrollBehavior } from './mixins/scroll_behavior_mixin.js';

// update the login route path once after login screen is approved by customer 
var router = new VueRouter({
  routes: [
    {
      name: 'login',
      path: '/users/sign_in',
      component: Login,
    },
    {
      name: 'forgot_password',
      path: '/forgot_password',
      component: ForgotPassword,
    }
  ],
  scrollBehavior,
  hashbang: false,
  mode: 'history',
  base: '/',
});

export default router;
