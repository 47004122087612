<template>
  <div
    class="text-center rounded-circle"
    :class="backgroundColorClass"
    :style="wrapperStyle"
  >
    <img
      v-tooltip="tooltipMessage"
      :height="imgHeightPixels"
      :style="imgStyle"
      :src="imgSrc"
      :alt="tooltipMessage"
    >
  </div>
</template>

<script>
  export default {
    props: {
      imgSrc: {
        type: String,
        required: true,
      },
      tooltipMessage: {
        type: String,
        required: false,
        default: '',
      },
      backgroundColorClass: {
        type: String,
        required: false,
        default: "bg-themed-box-bg",
      },
      imgHeightPixels: {
        type: Number,
        required: false,
        default: 32,
      },
      imgPaddingPixels: {
        type: Number,
        required: false,
        default: 6,
      },
    },
    computed: {
      wrapperSize() {
        return this.imgHeightPixels + (this.imgPaddingPixels * 2);
      },
      wrapperStyle() {
        return { width: `${this.wrapperSize}px`, height: `${this.wrapperSize}px`, 'line-height': `${this.wrapperSize}px` };
      },
      imgStyle() {
        return { width: 'auto', padding: `${this.imgPaddingPixels}px`, 'max-width': `${this.wrapperSize}px` };
      },
    },
  };
</script>
