export default {
  computed: {
    passwordValidation() {
      return {
        required: true,
        min: 8,
        regex: /^(?=.*[A-Za-z])(?=.*\d)[\w\W]{8,}$/
      };
    }
  },
}
