export const savePreviousScroll = (to, from, next) => {
  const scrollEl = document.querySelector('.simplebar-content-wrapper');
  if (scrollEl) {
    from.meta.scrollPosition = { x: scrollEl.scrollLeft, y: scrollEl.scrollTop };
  }
  next();
};

const scrollToPolyfill = (scrollEl, x, y) => {
  const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;

  if (supportsNativeSmoothScroll) {
    scrollEl.scrollTo(x, y);
  } else {
    scrollEl.scrollLeft = x;
    scrollEl.scrollTop = y;
  }
};

// Always returning null to prevent any unwanted window.scroll behavior
export const scrollBehavior = (to, from, savedPosition) => {
  if (to.meta.noScrollFrom?.includes(from.name) || from.meta.noScrollTo?.includes(to.name)) {
    return;
  }

  const scrollEl = document.querySelector('.simplebar-content-wrapper');

  if (scrollEl) {
    if (savedPosition && to.meta.scrollPosition) {
      // The `savedPosition` relies on window.scroll, but our scrolling takes place only on the .simplebar-content-wrapper.
      // We rely on `savedPosition` only to know when a popstate has occurred and a saved scroll position is expected.
      const realSavedPos = to.meta.scrollPosition;
      scrollToPolyfill(scrollEl, realSavedPos.x, realSavedPos.y);
      return;
    
    } else if (to.hash) {
      setTimeout(() => {
        const elem = document.querySelector(to.hash);
        if (elem) {
          elem.scrollIntoView();
        } else {
          scrollToPolyfill(scrollEl, 0, 0);
        }
      });

    } else {
      scrollToPolyfill(scrollEl, 0, 0);
      return;
    }
  }
};
