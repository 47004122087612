export default [
  {
    id: 1,
    name: "Brandon Hardy",
    role: "Perkin Hardy Strauss",
    quote: "Being shown how much we were spending on services was a wake up call. Seeing these reports has been incredibly helpful for the entire team.",
    img: "https://nulodgic-static-assets.s3.amazonaws.com/images/brandon-hardy.png",
  },
  {
    id: 3,
    name: "Talina M.",
    role: "Small Business Owner",
    quote: "Great business solution - the thorough inventory of assets, warranties, and contracts made this essential while forming our IT department.",
    img: "https://nulodgic-static-assets.s3.amazonaws.com/images/talina-m.jpg",
  },
  {
    id: 6,
    name: "Samantha K.",
    role: "System Admin",
    quote: "Genuity is amazingly easy to configure and deploy. All of the modules work together and make tracking ridiculously easy. And the price is borderline unbelievable.",
    img: "https://nulodgic-static-assets.s3.amazonaws.com/images/samantha-k-2.jpg",
  },
  {
    id: 5,
    name: "Joel F.",
    role: "Director of IT",
    quote: "Unbelievable value for a very low cost! I like that the way the solution is laid out allows for a complex structure.",
    img: "https://nulodgic-static-assets.s3.amazonaws.com/images/joel-f-2.jpg",
  },
  {
    id: 2,
    name: "Scott Kitun",
    role: "CEO at Technori",
    quote: "I know first-hand the needs of small businesses. Genuity has not only been a huge asset for us, but for the businesses we work with, as well.",
    img: "https://nulodgic-static-assets.s3.amazonaws.com/images/scott-kitun.png",
  },
  {
    id: 4,
    name: "Greg Bertsch",
    role: "Partner at Duggan Bertsch",
    quote: "Before Genuity, I was using spreadsheets to manage and track our IT spending. This is a cost effective solution that's already saved me thousands.",
    img: "https://nulodgic-static-assets.s3.amazonaws.com/images/greg-bertsch.png",
  },
];
