<template>
  <div class="sign-in-quotes flex-column justify-content-center p-5 position-relative align-items-center col-5 d-sm-none d-lg-flex">
    <div
      v-for="(quote, index) in quotes"
      :key="index"
      class="quote-item mb-5 p-5 d-flex flex-column justify-content-center"
    >
      <blockquote class="text-white text-italic readable-line-height">
        &ldquo;{{ quote.quote }}&rdquo;
      </blockquote>
      <div class="row mt-2">
        <div class="col-9 d-flex">
          <div class="avatar">
            <img
              width="50"
              height="50"
              class="m-0 p-0"
              :alt="quote.name"
              :src="quote.img"
            >
          </div>
          <div class="ml-3">
            <h2 class="h5 mb-1">
              {{ quote.name }}
            </h2>
            <h3 class="h6 text-secondary font-weight-normal">
              <span class="text-white">{{ quote.role }}</span>
            </h3>
          </div>
        </div>
        <div class="col-3 d-flex justify-content-center align-items-center">
          <i class="genuicon-star mr-1 text-yellow" />
          <i class="genuicon-star mr-1 text-yellow" />
          <i class="genuicon-star mr-1 text-yellow" />
          <i class="genuicon-star mr-1 text-yellow" />
          <i class="genuicon-star  text-yellow" />
        </div>
      </div>
    </div>
  </div>
</template>


<script>
  import Quotes from './quotes.js';
  import IconBadge from "../shared/icon_badge";

  const shuffle = (array) => {
    array.sort(() => Math.random() - 0.5);
    return array;
  };

  export default {
    components: {
      IconBadge,
    },
    data () {
      return {
        quotes: shuffle(Quotes).slice(0,2),
      }
    },
  };
</script>

<style scoped lang="scss">
.quote-item {
  background: rgba(255, 255, 255, 0.2);
  border-radius: $border-radius;
  backdrop-filter: blur(0.625rem);
  width: 27rem;
  color: white;

  .text-secondary {
    opacity: 0.4;
  }

  p.quote {
    line-height: 2.0rem;
    margin: 0;
    padding: 0;
  }

  &:last-child {
    margin-bottom: 0!important;
  }
}
.text-yellow {
  color: $yellow;
}
.avatar {
  border-radius: 50px;
  overflow: hidden;
  width: 50px;
  height: 50px;
}
</style>
