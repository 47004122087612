import Authentication from '../components/authentication.vue';
import router from '../routers/authentication';
import GlobalStore from '../stores/global_store.js';

const store = new Vuex.Store({
  modules: {
    GlobalStore: {
      namespaced: true,
      ...GlobalStore,
    },
  },
});

// eslint-disable-next-line no-unused-vars
const authentication = new Vue({
  router,
  store,
  el: '#authentication',
  components: { Authentication },
  template: '<authentication />',
});
